<template>
  <v-skeleton-loader v-if="loading" type="image" width="32" height="32" class="tw-rounded-full tw--ml-4 sm:tw-ml-4" />
  <div class="header-brands" :class="{ 'header-brands--open': isOpen }" v-else>
    <div
      class="header-brands__wrapper"
      :class="{ 'header-brands__wrapper--multi': brands.length > 1 }"
      @click.stop="brands.length > 1 ? toggleBrands('dropdown-brands') : false"
    >
      <img :src="currentClient.logo" v-if="currentClient.logo" class="header-brands__wrapper__logo" />
      <ui-logo v-else logo="logo-gtr.svg" width="36px" height="36px" class="header-brands__wrapper__logo" />
      <span class="header-brands__wrapper__caret backoffice-icons" v-if="brands.length > 1"> chevron_down </span>
    </div>
    <transition :name="isMobile ? 'slide-left' : 'tilt-up'">
      <div class="header-brands__dropdown" v-if="isOpen" @click.stop>
        <ul class="header-brands__dropdown__list">
          <li class="header-brands__dropdown__list__item">
            <ui-dropdown
              id="dropdown-brands"
              :value="currentClient"
              :options="brands"
              :no-absolute="true"
              :no-radius="true"
              :placeholder="$t('brands.dropdown.placeholder')"
              label="name"
              track-by="uuid"
              @input="onInput"
              @close="onClose"
            />
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import UiLogo from '@/components/UI/Logo.vue'
import UiDropdown from '@/components/UI/Dropdown.vue'

export default {
  name: 'HeaderBrands',
  components: {
    UiLogo,
    UiDropdown,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    brands: {
      type: Array,
      required: true,
    },
    currentClient: {
      type: Object,
      required: true,
    },
    isMobile: {
      type: Boolean,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    onInput(item) {
      this.$emit('updateCurrentClient', item)
    },
    onClose() {
      this.$emit('closeBrands')
    },
    toggleBrands(element) {
      this.$emit('toggleBrands', element)
    },
  },
}
</script>

<style lang="scss" scoped>
.header-brands {
  position: relative;

  &--open {
    z-index: 1;
  }

  &__wrapper {
    display: flex;
    align-items: center;

    &__logo {
      max-width: 90px;
      height: 35px;

      @media (min-width: $screen-sm) {
        max-width: 105px;
        height: 40px;
      }
    }
    &--multi {
      cursor: pointer;
    }
  }

  &__dropdown {
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    z-index: 2;
    background-color: var(--bg-color);
    min-width: 280px;

    @media (min-width: $screen-sm) {
      border-radius: $radius-default;
      box-shadow: 0 0 15px var(--box-shadow-color);
      min-width: 350px;
    }

    &__list {
      margin: 0;
      padding: 0;
      list-style: none;

      &__item {
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
