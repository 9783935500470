<template>
  <v-skeleton-loader v-if="loading" type="image" width="32" height="32" class="tw-rounded-full tw-mr-5" />
  <div class="header-modules" v-else>
    <component
      :is="$vuetify.breakpoint.xsOnly ? 'v-dialog' : 'v-menu'"
      offset-y
      left
      :close-on-content-click="false"
      max-width="360"
      min-width="360"
      :transition="$vuetify.breakpoint.xsOnly ? 'slide-x-transition' : 'slide-y-reverse-transition'"
      fullscreen
      v-model="isVisible"
    >
      <template v-slot:activator="{ on: component, attrs }">
        <v-tooltip bottom transition="fade-transition">
          <template v-slot:activator="{ on: tooltip }">
            <v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...component }">
              <v-icon>{{ icons.mdiApps }}</v-icon>
            </v-btn>
          </template>
          {{ $t('header.modules.tooltip') }}
        </v-tooltip>
      </template>
      <v-card
        class="header-modules__card"
        :tile="$vuetify.breakpoint.xsOnly"
        :height="$vuetify.breakpoint.xsOnly ? '100%' : 'auto'"
        :max-height="$vuetify.breakpoint.xsOnly ? '100%' : '364px'"
      >
        <v-toolbar class="primary tw-flex-grow-0" height="56" :elevation="2">
          <v-btn icon dark @click="toggleModules" v-if="$vuetify.breakpoint.xsOnly">
            <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
          </v-btn>
          <v-toolbar-title class="tw-text-base tw-font-medium tw-text-white">
            {{ $t('header.modules.tooltip') }}
          </v-toolbar-title>
        </v-toolbar>
        <v-list class="header-modules__card__list">
          <v-list-item
            class="header-modules__card__list__item"
            :class="{ 'theme--dark': $vuetify.theme.dark, 'v-list-item--active': module.module === currentModule }"
            link
            v-for="(module, i) in modules"
            :key="i"
            @contextmenu="showOpenNewTab($event, module)"
            @click="onClick(module)"
          >
            <v-avatar>
              <v-icon
                class="tw-bg-gray-100 tw-rounded-full primary--text"
                :class="{ 'tw-bg-gray-800': $vuetify.theme.dark }"
              >
                {{ moduleIcon(module.module) }}
              </v-icon>
            </v-avatar>
            <div class="tw-font-medium tw-mt-3">{{ moduleLabel(module.module) }}</div>
          </v-list-item>
          <v-menu :position-x="showNewTab.x" :position-y="showNewTab.y" absolute offset-y v-model="showNewTab.display">
            <v-list>
              <v-list-item dense link @click="onClick(showNewTab.item, true)">
                <v-icon color="#449afd" left small>{{ icons.mdiOpenInNew }}</v-icon>
                <v-list-item-content class="tw-text-sm">
                  {{ $t('header.modules.link.newTab') }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list>
      </v-card>
    </component>
  </div>
</template>

<script>
import { VMenu } from 'vuetify/lib'
import { VDialog } from 'vuetify/lib'
import { mdiArrowLeft, mdiApps, mdiOpenInNew } from '@mdi/js'
import { modules } from '@/config/modules.config'

export default {
  name: 'HeaderModules',
  components: {
    VMenu,
    VDialog,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    modules: {
      type: Array,
      required: true,
    },
    currentModule: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    showNewTab: {
      item: null,
      display: false,
      x: null,
      y: null,
    },
    isVisible: false,
    icons: {
      mdiArrowLeft,
      mdiApps,
      mdiOpenInNew,
    },
  }),
  methods: {
    showOpenNewTab(event, item) {
      event.preventDefault()
      this.showNewTab = {
        item: item,
        display: true,
        x: event.clientX,
        y: event.clientY,
      }
    },
    toggleModules() {
      this.isVisible = !this.isVisible
    },
    moduleLabel(moduleKey) {
      return this.$t(`module.${moduleKey}.label`)
    },
    moduleIcon(moduleKey) {
      return modules[moduleKey]?.icon
    },
    onClick(module, openNewTab) {
      if (this.currentModule !== module.module) {
        this.isVisible = false
        this.$emit('update', { module, openNewTab })
      }
    },
  },
}
</script>

<style lang="scss">
.header-modules {
  @apply tw-mr-1;

  &__card {
    @apply tw-flex tw-flex-col #{!important};

    &__list {
      @apply tw-overflow-y-auto tw-flex tw-flex-wrap #{!important};

      &__item {
        @apply tw-flex tw-flex-col tw-items-center tw-justify-center tw-p-4 #{!important};

        flex-basis: 50% !important;
        border-bottom: 1px solid;
        border-left: 1px solid;
        border-color: $grey-geyser;
        min-height: initial !important;

        &.theme--dark {
          border-color: rgba($grey-geyser, 0.12);
        }

        &:nth-child(odd) {
          border-left: 0;
        }

        .v-icon__svg {
          width: 24px;
        }
      }
    }
  }
}
</style>
