import { render, staticRenderFns } from "./Backoffice.vue?vue&type=template&id=f3075ce4&scoped=true&"
import script from "./Backoffice.vue?vue&type=script&lang=js&"
export * from "./Backoffice.vue?vue&type=script&lang=js&"
import style0 from "./Backoffice.vue?vue&type=style&index=0&id=f3075ce4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f3075ce4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VMain } from 'vuetify/lib/components/VMain';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAppBar,VMain,VOverlay,VProgressCircular,VSpacer})
