<template>
  <div
    class="backoffice"
    @click="closeEverything({ menu: true, notifications: true, account: true, brands: true })"
    :key="currentClientId"
  >
    <v-app-bar app height="56" class="tw-shadow">
      <HeaderMenu :loading="loading" @toggleDrawer="toggleDrawer" />

      <HeaderBrands
        :brands="clients"
        :loading="loading"
        class="top-bar__container__section__logo tw-ml-4"
        :is-open="isBrandsOpen"
        :is-mobile="false"
        :current-client="currentClient"
        :logo="currentClient.logo"
        @toggleBrands="toggleBrands"
        @closeBrands="closeBrands"
        @updateCurrentClient="redirectAndUpdateCurrentClient"
      />

      <v-spacer></v-spacer>

      <HeaderModules
        v-if="modules.length > 1"
        :loading="loading"
        :modules="modules"
        :current-module="currentModule"
        @update="updateModule"
      />

      <HeaderAccount :loading="loading" :current-user="currentUser" />
    </v-app-bar>
    <Navigation
      :drawer="drawer"
      :loading="loading"
      :updating="updating"
      :navigation="currentNavigation"
      :current-module="currentModule"
      @toggleDrawer="toggleDrawer"
    />
    <v-main class="tw-h-full">
      <v-overlay :value="loading || updating" v-if="loading || updating" absolute>
        <v-progress-circular indeterminate size="64" width="6"></v-progress-circular>
      </v-overlay>
      <router-view class="backoffice__views" v-else> </router-view>
    </v-main>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { getUserToken, isNavigationAccess, isExternalModule, isExternalMenu } from '@/utils/auth.util'
import { menus } from '@/config/menus.config'
import { URLS } from '@/config/urls.config'
import { getShortLocal } from '@/utils/string.util'
import { sleep, getRedirectedRouteIfExist } from '@/utils/utilities.util'
import Navigation from '@/components/Navigation.vue'
import HeaderMenu from '@/components/Header/Menu.vue'
import HeaderModules from '@/components/Header/Modules.vue'
import HeaderAccount from '@/components/Header/Account.vue'
import HeaderBrands from '@/components/Header/Brands.vue'

export default {
  name: 'Backoffice',
  components: {
    Navigation,
    HeaderMenu,
    HeaderModules,
    HeaderAccount,
    HeaderBrands,
  },
  async mounted() {
    await this.checkAuthentication()

    const route = this.$router.currentRoute
    const moduleByParams = route.params.module

    await isExternalModule(this.currentModule, route.params.clientId)
    if (!moduleByParams) this.goToFirstMenu()

    this.setLoading(false)
  },
  computed: {
    ...mapState({
      drawer: state => state.backoffice.drawer,
      loading: state => state.backoffice.loading,
      updating: state => state.backoffice.updating,
      currentUser: state => state.backoffice.currentUser,
      currentModule: state => state.backoffice.currentModule,
      navigation: state => state.backoffice.navigation,
      currentNavigation: state => state.backoffice.currentNavigation,
      modules: state => state.backoffice.modules,
      isBrandsOpen: state => state.backoffice.isBrandsOpen,
      clients: state => state.client.clients,
      currentClient: state => state.client.currentClient,
      currentClientId: state => state.client.currentClient?.id,
    }),
    ...mapGetters({
      firstParentMenu: 'backoffice/firstParentMenu',
      firstChildMenu: 'backoffice/firstChildMenu',
    }),
  },
  methods: {
    ...mapActions({
      setDrawer: 'backoffice/setDrawer',
      setLoading: 'backoffice/setLoading',
      setUpdating: 'backoffice/setUpdating',
      getAuthentication: 'backoffice/getAuthentication',
      getMenus: 'backoffice/getMenus',
      userLogout: 'backoffice/userLogout',
      closeEverything: 'backoffice/closeEverything',
      toggleBrands: 'backoffice/toggleBrands',
      updateCurrentClient: 'client/updateCurrentClient',
      updateCurrentNavigation: 'backoffice/updateCurrentNavigation',
    }),
    toggleDrawer(bool) {
      this.setDrawer(typeof bool === 'boolean' ? bool : !this.drawer)
    },
    async checkAuthentication() {
      if (getUserToken() !== undefined) {
        try {
          await this.getAuthentication()
          this.$i18n.locale = getShortLocal(this.currentUser.locale)
          if (!isNavigationAccess(this.$route.meta.roles, this.currentUser)) {
            this.$router.push({ name: 'Error' })
          }
        } catch {
          await this.userLogin()
        }
      } else {
        await this.userLogin()
      }
    },
    async updateModule({ module, openNewTab }) {
      await isExternalModule(module, this.$route.params.clientId, openNewTab)

      if (!openNewTab) {
        this.setUpdating(true)
        this.$store.commit('backoffice/SET_CURRENT_MODULE', module.module)
        this.updateCurrentNavigation(this.currentModule)
        this.goToFirstMenu()
      }
      this.setUpdating(false)
    },
    async goToFirstMenu() {
      const firstRouteMenu = menus[this.firstParentMenu.key]?.items[this.firstChildMenu.key]?.route
      await isExternalMenu(this.firstChildMenu)
      if (this.$route.name !== firstRouteMenu)
        this.$router.push({
          name: firstRouteMenu,
          params: {
            module: this.currentModule,
          },
        })
    },
    async redirectAndUpdateCurrentClient(newCurrentClient) {
      const currRouteName = this.$route.name
      const { isRedirect, route } = getRedirectedRouteIfExist(currRouteName)

      if (isRedirect) {
        this.setUpdating(true)
        await this.updateCurrentClient(newCurrentClient)
        this.$router.push(route)
        this.setUpdating(false)
      } else {
        this.updateCurrentClient(newCurrentClient)
      }
    },
    closeBrands() {
      this.closeEverything({ brands: true })
    },
    async userLogin() {
      await this.userLogout()
      window.location.assign(
        `${URLS.SSO}/?app_version=2&redirect=${encodeURIComponent(location.pathname + location.search)}&sign_out=1`
      )
      await sleep(2000)
    },
  },
}
</script>

<style lang="scss" scoped>
.backoffice {
  height: 100%;

  &__views {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__menu-mobile {
    display: flex;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: center;
    z-index: 5;
    border-top: 1px solid $navigation-color-border;
    height: $navigation-height;

    @media (min-width: $screen-md) {
      display: none;
    }
  }
}
</style>
